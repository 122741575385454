import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'antibody/:id',
    loadChildren: () => import('./antibody/antibody.module').then( m => m.AntibodyPageModule)
  },
  {
    path: 'disease/:id',
    loadChildren: () => import('./disease/disease.module').then( m => m.DiseasePageModule)
  },
  {
    path: 'symptom/:id',
    loadChildren: () => import('./symptom/symptoms.module').then( m => m.SymptomsPageModule)
  },
  {
    path: 'antibody-list',
    loadChildren: () => import('./antibody-list/antibody-list.module').then( m => m.AntibodyListPageModule)
  },
  {
    path: 'symptoms-list',
    loadChildren: () => import('./symptoms-list/symptoms-list.module').then( m => m.SymptomsListPageModule)
  },
  {
    path: 'disease-list',
    loadChildren: () => import('./disease-list/disease-list.module').then( m => m.DiseaseListPageModule)
  },
  {
    path: 'antibody-form',
    loadChildren: () => import('./antibody-form/antibody-form.module').then( m => m.AntibodyFormPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'disease-form',
    loadChildren: () => import('./disease-form/disease-form.module').then( m => m.DiseaseFormPageModule)
  },
  {
    path: 'symptom-form',
    loadChildren: () => import('./symptom-form/symptom-form.module').then( m => m.SymptomFormPageModule)
  },
  {
    path: 'disease-search',
    loadChildren: () => import('./disease-search/disease-search.module').then( m => m.DiseaseSearchPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBy1tXnVELqKuG9Mcl3AOQukiXNNTThKPU",
    authDomain: "immuno-reference.firebaseapp.com",
    databaseURL: "https://immuno-reference.firebaseio.com",
    projectId: "immuno-reference",
    storageBucket: "immuno-reference.appspot.com",
    messagingSenderId: "678643552433"
  }
};


export const webClientId = '678643552433-itupj2obl6kn2vas0lsaq2iugrhq277a.apps.googleusercontent.com';

export const storageURLs = {
  fbsUsers64: 'https://firebasestorage.googleapis.com/v0/b/immuno-reference.appspot.com/o/users%2Fthumb%4064_{1}.jpg?alt=media',
  fbsUsers500: 'https://firebasestorage.googleapis.com/v0/b/immuno-reference.appspot.com/o/users%2Fthumb%40500_{1}.jpg?alt=media',  
  fbsAntibodies120: 'https://firebasestorage.googleapis.com/v0/b/immuno-reference.appspot.com/o/antibodies%2F{1}%2Fthumb%40120_{2}.jpg?alt=media',
  fbsAntibodies500: 'https://firebasestorage.googleapis.com/v0/b/immuno-reference.appspot.com/o/antibodies%2F{1}%2Fthumb%40500_{2}.jpg?alt=media',
  fbsImage: 'https://firebasestorage.googleapis.com/v0/b/immuno-reference.appspot.com/o/{1}?alt=media'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

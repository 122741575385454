import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { UserService } from "./user.service";
import { AuthService } from "./auth.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user;

  public appPages = [
    { title: 'Main', url: '/', icon: 'home' },    
    { title: 'Antibodies', url: '/app/antibodies', icon: 'antibody' },
    { title: 'Diseases', url: '/app/diseases', icon: 'disease' },
    { title: 'Signs and Symptoms', url: '/app/symptoms', icon: 'accessibility' },
    { title: 'Differential Diagnosis', url: '/disease-search', icon: 'search' },
  ];

  public extraPages: any[] = [    
    { title: 'About us', url: '/about', icon: 'people-outline' },
    { title: 'Contact us', icon: 'mail-outline', action: 'contact' },
    { title: 'Terms of service',icon: 'document-text-outline', action: 'terms' },
    { title: 'Logout', url: '', icon: 'log-out-outline', action: 'logout' }

  ]

  constructor(private platform: Platform,
    private menu: MenuController,
    private storage: Storage,
    private router: Router,
    private userService: UserService,
    private authService: AuthService) {
      
    this.platform.ready().then(async () => {
      this.initApp();
    });

  }

  async initApp() {
    this.authService.getUser().then(user => { 
      if (!user) this.router.navigateByUrl('/login'); 
      else {
        this.user = user;
        // Check if navigating to a specific URL
        if (this.router.url == '/') this.router.navigateByUrl('/');
        else this.router.navigateByUrl(this.router.url);  
      }
    });
  }
    
  gotoPage(url) {
    this.router.navigateByUrl(url);
  }

  openPage(page) {
    if (page.action === 'logout') {      
      this.authService.logout();    // Give the menu time to close before changing to logged out
      return;
    }
    if (page.action === 'contact') {      
      let a = document.createElement('a');
      a.href = 'mailto:immunoreference@gmail.com';
      let div = document.getElementsByClassName('categories')[0];
      div.appendChild(a);
      a.click();
    }
    if (page.action === 'terms') {      
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = 'https://firebasestorage.googleapis.com/v0/b/immuno-reference.appspot.com/o/terms_and_conditions.html?alt=media';
      let div = document.getElementsByClassName('categories')[0];
      div.appendChild(a);
      a.click();
    }
    else {
      this.router.navigateByUrl(page.url);

      // Reset the content nav to have just this page
      // we wouldn't want the back button to show in this scenario
      //this.nav.setRoot(page.component);
    }    
  }
}

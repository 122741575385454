import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Plugins } from '@capacitor/core'

import 'firebase/auth';
import '@codetrix-studio/capacitor-google-auth';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,
    private storage: Storage,
    public platform: Platform) { 

    this.init();
  }

  async init() {
    await this.storage.create();
  }

  async register(data) {
    let res = await firebase.default.auth().createUserWithEmailAndPassword(data.email, data.password);
    console.log(res.user);
    await this.storage.set('authuser' , JSON.stringify(res.user));
    return res;
  }

  async loginEmail(user) {    
    let res = await firebase.default.auth().signInWithEmailAndPassword(user.email, user.password);
    console.log(res.user);
    await this.storage.set('authuser' , JSON.stringify(res.user));
    return res;
  }
  
  async loginGoogle() {
  	if (this.platform.is('capacitor')) {
      const result = await Plugins.GoogleAuth.signIn();
      const credential = firebase.default.auth.GoogleAuthProvider.credential(result.authentication.idToken);
      let res = await firebase.default.auth().signInWithCredential(credential);
      console.log(res.user);
      await this.storage.set('authuser' , JSON.stringify(res.user));
      return res;
    }
    else {
      let res = await firebase.default.auth().signInWithPopup(new firebase.default.auth.GoogleAuthProvider()); 
      console.log(res.user);
      await this.storage.set('authuser' , JSON.stringify(res.user));
      return res;
    }
  }
  
  async loginFacebook() {
  	if (this.platform.is('capacitor')) {
      const result = await Plugins.FacebookLogin.login({ permissions: ['public_profile', 'email'] });
      if (result && result.accessToken) {
        const credential = firebase.default.auth.FacebookAuthProvider.credential(result.authentication.idToken);
        let res = await firebase.default.auth().signInWithCredential(credential);
        console.log(res.user);
        await this.storage.set('authuser' , JSON.stringify(res.user));
        return res;
      }
    }
    else {
      let res = await firebase.default.auth().signInWithPopup(new firebase.default.auth.FacebookAuthProvider()); 
      console.log(res.user);
      await this.storage.set('authuser' , JSON.stringify(res.user));
      return res;
    }
  }

  logout() {
    return firebase.default.auth().signOut().then(res => {
      this.storage.clear();
      localStorage.clear();
      this.router.navigateByUrl('/login')
    })
  }

  async getUser() {
    const user = await this.storage.get('authuser'); 
    return user ? JSON.parse(user) : null; 
  }

  
  async isConnected() {  	
    const user = await this.storage.get('authuser'); 
    return !!user;
  }
}

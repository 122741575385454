import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private storage: Storage) {

    this.init();
  }

  async init() {
    await this.storage.create();
  }

  async setUser(user) {
    const authuser = await this.storage.get('authuser'); 
    if (authuser) {
      let data = JSON.parse(authuser);
      user.uid = data.uid;
    }
    user.displayName = `${user.firstName} ${user.lastName}`
    await this.storage.set('user', user);
  }

  getUser() {
    return this.storage.get('user').then((user) => {
      if (!user) return null; 
      return user;
    });
  }

  updateUser(data) {
    var self = this;
    return this.getUser().then(account => {
      for (let k in data) account[k] = data[k];
      return self.setUser(account);
    });
  }
  
}
